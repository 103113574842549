// import store from '@/store'

export default [
  {
    path: '/brands',
    name: 'brands',
    component: () => import('@/views/brands/List.vue'),
    meta: {
      pageTitle: 'Бренды',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Бренды',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brands/create',
    name: 'brands-create',
    component: () => import('@/views/brands/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание бренда',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Бренды',
          active: false,
          to: '/users',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brands/update/:id',
    name: 'brands-update',
    component: () => import('@/views/brands/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Бренды',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Бренды',
          active: false,
          to: '/brands',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },
]
